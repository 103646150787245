/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import SEO from "@components/seo"
import CTAHeadingBlock from "@components/CTAHeadingBlock"
import Layout from "@components/Layouts"
import Container from "@components/Container"
import CalloutTextBlocks from "@components/CalloutTextBlocks"
import TwoColumnSection, {
  Block as ColumnBlock,
  ImageBlock as ColumnImageBlock,
} from "@components/TwoColumnSection"
import Section from "@components/Section"
import ImageTextBlocksList from "@components/ImageTextBlocksList"
import { rem } from "@src/theme"
import { formatMarkdownToHtml } from "@helpers"
import MarkdownBody from "@modules/MarkdownBody"
import ImageGallery from "@components/ImageGallery"
import CallToAction from "@components/CallToAction"
import QuickLinks from "@components/QuickLinks"
import IconThreeUp from "@components/IconThreeUp"
import TextCarousel from "@components/TextCarousel"
import styled from "styled-components"
import BannerCta from "@components/BannerCta"
import TestimonialCarousel from "@components/TestimonialCarousel"
import Accordion from "@components/Accordion"
import Spacer from "@components/Spacers"
import { DonateSection } from "@components/ImpactFooter"
import PartnerLogos from "@components/PartnerLogos"


const GetStarted = () => {
  const data = useStaticQuery(
    graphql`
      query {
        pageData: markdownRemark(
          fileAbsolutePath: { glob: "**/pages/get-started/index.md" }
        ) {
          frontmatter {
            header {
              ...Header
            }
            threeColumnTextBlock {
              heading
              columns {
                image
                heading
                blockHeading
                text
                ctaButton {
                  label
                  link
                }
              }
            }
            imageGallery{
              image1
              image2
              image3
              image4
              image5
              image6
            }
            threeUpBlock {
                heading
                columns {
                    text
                }
            }
            carouselStats {
              ...CarouselStats
            }
            imageTextBlock {
              gradientBackground
                rows {
                image
                heading
                text
                links {
                  label
                  link
                }
              }
            }
            smallCta {
                image
                imageFit
                heading
                text
                backgroundImg
                ctaButton {
                    label
                    link
                }
            }
            ctaBlock {
              heading
              subheading
              text
              ctaButton {
                label
                link
              }
            }
            carousel {
                heading
                image
                text
                footnote
                ctaButton {
                    label
                    link
                }
            }
            quickLinks {
              heading
              links {
                image
                heading
                text
                link
              } 
            }
            accordion {
                ...Accordion
            }
            ogTitle
            ogimage
            ogdescription
          }
        }
      }
    `
  )

  return <GetStartedTemplate data={data} />
}


export const GetStartedTemplate = ({ data }) => {
  const { frontmatter } = data.pageData
  const {
    header,
    banner,
    ogTitle,
    ogimage,
    ogdescription,
    threeColumnTextBlock,
    imageGallery,
    imageTextBlock,
    ctaBlock,
    quickLinks,
    threeUpBlock,
    carouselStats,
    smallCta,
    carousel,
    accordion,
    partnerLogos
  } = frontmatter

  console.info("Content", frontmatter)

  return (
    <Layout>
      <SEO title={ogTitle || header?.heading} image={ogimage} ogdescription={ogdescription} />
      {banner && (
        <MarkdownBody
          html={formatMarkdownToHtml(banner)}
          sx={{
            bg: "turquoise",
            color: "darkPurple",
            textAlign: "center",
            py: rem(25),

            "h1,h2,h3,h4,h5": {
              my: 0,
            },
          }}
        />
      )}
      {header && header.image ? (<TwoColumnSection
        col1={
          <div>
            <ColumnBlock
              styles={{
                bg: "purple",
                color: "pink",
              }}
            >
              <CTAHeadingBlock heading={header.heading} />
            </ColumnBlock>
            <ColumnBlock
              styles={{
                bg: "pink",
                color: "darkPurple",
              }}
            >
              <CTAHeadingBlock
                heading={header.subheading}
                body={header.subtext}
                buttonLabel={header.ctaButton?.label}
                buttonLink={header.ctaButton?.link}
                smallHeading
              />
            </ColumnBlock>
          </div>
        }
        col2={<ColumnImageBlock image={header.image} />}
      />) : (<TwoColumnSection
        col1={
          <ColumnBlock
            styles={{
              bg: "purple",
              color: "pink",
            }}
          >
            <CTAHeadingBlock heading={header.heading} />
          </ColumnBlock>
        }
        col2={<ColumnBlock
          styles={{
            bg: "pink",
            color: "darkPurple",
          }}
        >
          <CTAHeadingBlock
            heading={header.subheading}
            body={header.subtext}
            buttonLabel={header?.ctaButton?.label}
            buttonLink={header?.ctaButton?.link}
            smallHeading
          />
        </ColumnBlock>}
      />)}
      {threeUpBlock && threeUpBlock?.columns?.length > 0 &&
        <IconThreeUp
          textBlocks={threeUpBlock.columns.map((item) => item.text)}
          heading={threeUpBlock.heading}
        />
      }
      {carousel && carousel.length > 0 && (
        <Section>
          <TestimonialCarousel testimonials={carousel} />
        </Section>)}
      {threeColumnTextBlock && threeColumnTextBlock.map(row => (
        <Container>
          <Section>
            <CalloutTextBlocks blocks={row.columns} heading={row.heading} />
          </Section>
        </Container>
      ))}
      {imageTextBlock && imageTextBlock?.rows?.length > 0 && (
        imageTextBlock.gradientBackground ?
          (<GradientWrapper>
            <ImageTextBlocksList blocks={imageTextBlock.rows} />
          </GradientWrapper>
          ) :
          (<ImageTextBlocksList blocks={imageTextBlock.rows} />)
      )}
      {carouselStats && carouselStats.length > 0 && <Section>
        <TextCarousel blocks={carouselStats} />
      </Section>}
      {accordion && accordion?.accordionItems?.length > 0 && (
        <>
          <Spacer.Medium />
          <Accordion heading={accordion.heading} items={accordion.accordionItems} />
          <Spacer.Medium />
        </>
      )}
      {quickLinks && <QuickLinks heading={quickLinks.heading} quickLinks={quickLinks.links} />}
      {smallCta && smallCta.heading && <BannerCta
        heading={smallCta.heading}
        text={smallCta.text}
        imageFit={smallCta.imageFit}
        cta={smallCta?.ctaButton && {
          url: smallCta?.ctaButton?.link,
          text: smallCta?.ctaButton?.label,
        }}
        img={{
          src: smallCta.image,
          alt: smallCta.heading,
        }}
        backgroundImg={smallCta.backgroundImg}
      />}
      {imageGallery && Object.values(imageGallery).filter((value) => value !== "").length > 0 && (
        <ImageGallery {...imageGallery} />
      )}
      <CallToAction {...ctaBlock} />
      {partnerLogos && <PartnerLogos {...partnerLogos} />}
      {/* <DonateSection /> */}
    </Layout>
  )
}

const GradientWrapper = styled.div`
background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(132, 106, 175, 0.5) 100%
  ),
  #ffd1dc;
`



export default GetStarted
